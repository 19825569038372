
.about-main .ongoing-git-form {
  position: relative;
}

.about-main .ongoing-git-form img {
  height: 80rem;
  width: 100%;
}

@media screen and (max-width:480px) {
  .about-main .ongoing-git-form img {
    height: 60rem;
  }
}

.about-main .ongoing-git-form .form-class {
  width: 40rem;
  padding: 0.2rem;
  background-color: transparent;
  border: 0.5rem solid white;
  position: absolute;
  top: 17%;
  left: 15%;
}

@media screen and (max-width:594px) {
  .about-main .ongoing-git-form .form-class {
    top: 10% !important;
  }
}

@media screen and (max-width:480px) {
  .about-main .ongoing-git-form .form-class {
    margin-top: 2rem !important;
    left: 5% !important;
  }
}

@media screen and (max-width:640px) {
  .about-main .ongoing-git-form .form-class {
      top: 10rem;
  }
}

/* @media screen and (max-width:365px) {
  .about-main .ongoing-git-form img {
      height: 40rem !important;
  }
} */

.about-main .ongoing-git-form .form-class .form-main {
  background-color: transparent;
  padding: 1rem;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.about-main .ongoing-git-form .form-class .form-main .git-heading {
  padding: 1rem 0rem;
  font-weight: bold;
  font-size: 4rem;
  color: white;
  border-bottom: 0.3rem solid white;
}

.about-main .ongoing-git-form .form-class .form-main input,
.about-main .ongoing-git-form .form-class .form-main textarea {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  background-color: transparent;
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  font-size: 2rem;
  color: white;
}

.about-main .ongoing-git-form .form-class .form-main input::placeholder,
.about-main .ongoing-git-form .form-class .form-main textarea::placeholder {
  color: white;
}

.about-main .ongoing-git-form .form-class .form-main input {
  border: none;
  border-bottom: 0.3rem solid white;
}

.about-main .ongoing-git-form .form-class .form-main textarea {
  border-color: white;
}

.about-main .ongoing-git-form .form-class .form-main button {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background-color: rgba(255, 255, 255, 0.79);
  color: black;
}

.about-main .abouttitle {
  font-size: 5rem;
  font-weight: 700;
}
/* .about-main .about-main {
  margin-top: 4rem;
} */
.about-main .aboutsubttile {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
}
.about-main .abouttext {
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 3rem;
  font-weight: 500;
  text-align: justify;
  padding-right: 5rem;
}

.about-main .aboutleft {
  margin-left: 6rem !important;
  margin-top: 1rem;
}

@media screen and (max-width:1024px) {
  .about-main .aboutleft {
    margin-left: 0 !important;
  }
}

.about-main .aboutimg {
  /* height: 50rem; */
  border-radius: 50%;
  background-color: white;
}

/* @media screen and (max-width:992px) {
  .about-main .aboutimg {
    height: 50rem;
    border-radius: 50%;
    width: 50rem;
  }
} */

.about-main .aboutwhatwedo {
    background: linear-gradient(180deg, rgba(241, 246, 251, 0) 0%, #DCEAED 100%);

}
@media (max-width: 576px) {
  .about-main .aboutleft {
    margin: 2rem !important;
  }
  .about-main .abouttext {
    line-height: 3.5rem;
    font-size: 2rem;
    }
  .about-main .aboutimg {
    height: 30rem;
    border-radius: 50%;
  }
  .about-main .founder-description{
    font-size:2.5rem !important;
  }
}

/* Styles for medium screens */
@media (min-width: 577px) and (max-width: 992px) {
  .about-main .aboutleft {
    margin: 2rem !important;
  }
  .about-main .abouttext {
    line-height: 1.5rem;
  }
  .about-main .aboutimg {
    height: 40rem;
    border-radius: 50%;
  }
}

.about-main .founderleftside{
    background: linear-gradient(180deg, #A4C684 0%, #DAEDC6 33.33%, #FDFDFD 100%);
    width: 100%;
    margin-top: -15rem;
    padding-top: 20rem;
}

.about-main .foundertext img {
  background-color: transparent;
}

.about-main .founder-description{
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    font-weight: 500;
    text-align: justify;
    width: 90%;
}

.about-main .founders img{ 
    width: 100%;
}

.about-main .about-contact-section {
  margin-top: -0.2rem;
}