.projects-summary-main {
    background-image: url('../Images/ShadowImageforProject.png');
    padding: 7rem;
    background-size: cover;

}

.projects-summary-main .projects-summary .summary-title {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 3rem;
}

.projects-summary-main .projects-summary  .inner-circle {
    height: 23rem;
    width: 23rem;
    background-color: rgba(211, 229, 190, 1);
    border: 1rem white solid;
    border-radius: 50%;
    margin-top: 5rem;
    margin-bottom: 3rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5) !important;
}

.projects-summary-main .projects-summary  .inner-circle h1 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2.5rem;
    /* margin-top: 9rem; */
}