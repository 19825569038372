.contact-main {
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 63rem;
}

@media screen and (max-width:997px) {
    .contact-main {
        height: 71rem;
    }
}


@media screen and (max-width:990px) {
    .contact-main {
        height: 192rem;
    }
}

@media screen and (max-width:974px) {
    .contact-main {
        height: 190rem;
    }
}

@media screen and (max-width:880px) {
    .contact-main {
        height: 183rem;
    }
}

@media screen and (max-width:871px) {
    .contact-main {
        height: 178rem;
    }
}

@media screen and (max-width:790px) {
    .contact-main {
        height: 177rem;
    }
}

@media screen and (max-width:767px) {
    .contact-main {
        height: 183rem;
    }
}

@media screen and (max-width:690px) {
    .contact-main {
        height: 182rem;
    }
}
@media screen and (max-width:690px) {
    .contact-main {
        height: 182rem;
    }
}
@media screen and (max-width:480px) {
    .contact-main {
        height: 168rem;
    }
}

@media screen and (max-width:479px) {
    .contact-main {
        height: 188rem;
    }
}
@media screen and (max-width:429px) {
    .contact-main {
        height: 179rem;
    }
}



.contact-main .contact-content {
    /* padding-bottom: 0.1rem; */
    width: 100%;
    /* background-color: #91B171; */
}

.contact-main .contact-content .contact-row-main {
    height: 100%;
    /* max-width: 100%; */
}

.contact-main .contact-content .contact-row-main .contact-image img {
    height: 75%;
    width: 105% !important;
}

@media screen and (max-width:991px) {
    .contact-main .contact-content .contact-row-main .contact-image img {
        height: 100%;
    }
}


.contact-main .contact-content .contact-row-main .contact-details-main {
    padding: 3rem 2.5rem 35rem 0 !important;
    background-color: #91B171;
    /* margin-left: -1.5rem !important; */
}

@media screen and (max-width:991px) {
    .contact-main .contact-content .contact-row-main .contact-details-main {
        padding: 0 2.5rem 7rem 2.5rem !important;
    }
}

.contact-main .contact-content .contact-row-main .contact-details-main .details-main {
    /* background-color: #E1F0D1; */
    background-color: white;
    height: 90%;
}


@media screen and (max-width:480px) {
    .contact-main .contact-content .contact-row-main .contact-details-main .details-main {
        margin-top: 6rem !important;
    }
}

.contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin {
    margin-left: 3rem;
    margin-top: 2rem;
}

.contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 3rem;
    position: relative;
    top: 2rem;
}

.contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin .details-row-main {
    margin-top: 4rem;
    /* margin-bottom: 2rem; */
    width: 100%;
}

.contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin .details-row-main .contact-details h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
}

@media screen and (max-width:991px) {
    .contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin .details-row-main .contact-details h1 {
        font-size: 2.5rem;
        font-weight: 500;
    }
}

@media screen and (max-width:480px) {
    .contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin .details-row-main .contact-details h1 {
        font-size: 2.8rem;
        font-weight: 500;
    }
}

.contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin .details-row-main .contact-details .location {
    width: 100%;
}

@media screen and (max-width:480px) {
    .contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin .details-row-main .contact-details .location {
        width: 90% !important;
    }
}

/* .contact-main .contact-content .contact-row-main .contact-details-main .details-main 
.contact-margin .details-row-main .contact-details .email {
    margin-top: 2rem;
}

.contact-main .contact-content .contact-row-main .contact-details-main .details-main 
.contact-margin .details-row-main .contact-details .phone {
    margin-top: 2rem;
} */

.contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin .details-row-main .contact-details svg {
    margin-right: 1rem;
    font-size: 2rem;
}

@media screen and (max-width:991px) {
    .contact-main .contact-content .contact-row-main .contact-details-main .details-main .contact-margin .details-row-main .contact-details svg {
        margin-right: 1rem;
        font-size: 3.5rem;
    }
}

.details-row-main .contact-details .links {
    margin-top: 5rem;
    padding-left: 0 !important;
}

.details-row-main .contact-details .links h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700 !important;
    font-size: 3rem !important;
}

@media screen and (max-width:991px) {
    .details-row-main .contact-details .links h1 {
        font-size: 3.5rem !important;
    }
}

.details-row-main .contact-details .links li::before {
    content: ">";
    font-family: 'Poppins', sans-serif;
    font-weight: 400 !important;
    font-size: 2rem !important;
    padding: 1rem 1rem 1rem 0;
}

.details-row-main .contact-details .links li {
    list-style-type: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1.8rem !important;
    margin-top: 0.5rem !important;
}

@media screen and (max-width:550px) {
    .details-row-main .contact-details .links li {
        font-size: 1rem !important;
    }

    .details-row-main .contact-details .links li::before {
        font-size: 2rem !important;
        padding: 0;
    }
}

.details-row-main .contact-details .links li a{
    text-decoration: none;
    color: black;
}

@media screen and (max-width:991px) {
    .details-row-main .contact-details .links li {
        font-size: 2.5rem !important;
    }
}

.details-row-main .contact-details .links .home {
    margin-top: 2rem;
}

.details-row-main .contact-details .links .about {
    margin-top: 2rem;
}

.details-row-main .contact-details .links .done-projects {
    margin-top: 2rem;
}

.details-row-main .contact-details .links .ongoing-projects {
    margin-top: 2rem;
}

/* .contact-margin .details-row-main .contact-form {
    padding: 2rem;
} */

.contact-margin .details-row-main .contact-form .form-main {
    width: 38rem;
    padding: 2rem;
    /* margin-left: 10rem; */
    background-color: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 2rem;
}

.contact-margin .details-row-main .contact-form input,
.contact-margin .details-row-main .contact-form textarea {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
    font-size: 1.5rem;
}

.contact-margin .details-row-main .contact-form .form-main ::placeholder {
    color: black !important;
}

@media screen and (max-width:991px) {

    .contact-margin .details-row-main .contact-form input,
    .contact-margin .details-row-main .contact-form textarea {
        font-size: 2.2rem;
    }

    .contact-margin .details-row-main .contact-form .form-main {
        width: 45rem;
        margin-left: 0;
        margin-top: 5rem;
        margin-bottom: 4rem;
    }
}

.contact-margin .details-row-main .contact-form input {
    border: none;
    border-bottom: 1px solid black;
}

.contact-margin .details-row-main .contact-form button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.7rem;
    width: 100%;
    margin-top: 2rem;
    background-color: #5A8540;
    color: white;
}

@media screen and (max-width:991px) {
    .contact-margin .details-row-main .contact-form button {
        font-size: 2.8rem;
        width: 100%;
        margin-top: 2rem;
    }
}

/* .contact-main .contact-content .contact-row-main .contact-details-main .social-icons {
    margin-top: -2rem;
    text-align: end;
} */

.contact-main .contact-content .contact-row-main .contact-details-main .social-icons span{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    color: white;
    margin-right: 2rem;
    margin-top: 2rem !important;
}

.contact-main .contact-content .contact-row-main .contact-details-main .social-icons img{
    height: 4rem;
    margin-right: 0.4rem !important;
    margin-top: -1rem !important;
}

/* .contact-main .contact-content .contact-row-main .contact-details-main .copyright-footer {
    margin-top: -4rem !important;
} */


.contact-main .contact-content .contact-row-main .contact-details-main .copyright-footer h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.7rem;
}

.contact-main .contact-content .contact-row-main .contact-details-main .copyright-footer img {
    height: 4.5rem !important;
}