
.counter-main .counter {
    padding: 5rem 1rem;
}

.counter-main .counter .data p {
    font-family: Poppins;
    font-size: 2.2rem;
    font-weight: 600;
    color: white;
}

.counter-main .counter .data h1 {
    font-family: Poppins;
    font-size: 5rem;
    font-weight: 600;
    color: white;
}

@media screen and (max-width:479px) and (min-width:500px) {
    .counter-main .counter .data p {
        font-size: 2.2rem !important;
    }
    
    .counter-main .counter .data h1 {
        font-size: 3rem !important;
    }
}