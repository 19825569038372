
.ongoing-main .layout-carousel-main {
    padding: 2rem 5rem 1rem 5rem;
    background-color: #E0F0D0;
}

.ongoing-main .layout-carousel-main .ongoingProject-layoutCarousel-main {
    width: 90%;
    margin-left: 5%;
}

.ongoing-main .layout-carousel-main .ongoingProject-layoutCarousel-main .carousel-image img{
    height: 50rem;
    width: 100%;
}