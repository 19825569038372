.App {
  width: 100%;
  overflow: hidden !important;
}

.Toastify__toast-body {
  font-size: 1.5rem !important;
}


/* Default font size for larger screens */
html {
  font-size: 62.5%;
  /* 1rem = 10px, 62.5% of 16px = 10px */
}

@media all and (max-width: 479px) {
  html {
    font-size: 31.25%;
    /* 1rem = 5px, 31.25% of 16px = 5px */
  }
}

@media all and (min-width: 480px) and (max-width: 767px) {
  html {
    font-size: 43.75%;
    /* 1rem = 7px, 43.75% of 16px = 7px */
  }
}

/* Additional breakpoints and adjustments */
@media all and (min-width: 768px) and (max-width: 991px) {
  html {
    font-size: 50%;
    /* font-size: 45%; */
    /* 1rem = 8px, 50% of 16px = 8px */
  }
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  html {
    font-size: 56.25%;
    /* font-size: 50%; */
    /* 1rem = 9px, 56.25% of 16px = 9px */
  }
}

@media all and (min-width: 1200px) {
  html {
    font-size: 62.5%;
    /* Reset to default for larger screens */
  }
}