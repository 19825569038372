.hometitlebanner2 {
    font-size: 5rem;
    font-weight: 700;
    margin-top: 8rem;
}

.home-main .ongoing-git-form {
    position: relative;
}

.home-main .ongoing-git-form img {
    height: 80rem;
    width: 100%;
}

@media screen and (max-width:480px) {
    .home-main .ongoing-git-form .image-form-main {
        height: 58rem !important;
    }
}

.home-main .ongoing-git-form .form-class {
    width: 40rem;
    padding: 0.2rem;
    background-color: transparent;
    border: 0.5rem solid rgba(19, 147, 55, 1);
    position: absolute;
    top: 13rem;
    left: 15%;
}

@media screen and (max-width:480px) {
    .home-main .ongoing-git-form .form-class {
        left: 3%;
        margin-top: 3rem !important;
    }
}

@media screen and (max-width:810px) {
    .home-main .ongoing-git-form .form-class {
        top: 10%;
    }
}

@media screen and (max-width:566px) {
    .home-main .ongoing-git-form .form-class {
        margin-top: 2rem !important;
    }
}

@media screen and (max-width:640px) {
    .home-main .ongoing-git-form .form-class {
        top: 10rem;
    }
}

/* @media screen and (max-width:365px) {
    .home-main .ongoing-git-form .ongoing-git-form img {
        height: 60rem;
    }
} */

.home-main .ongoing-git-form .form-class .form-main {
    background-color: transparent;
    padding: 1.2rem;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.home-main .ongoing-git-form .form-class .form-main .git-heading {
    padding: 1rem 0rem;
    font-weight: bold;
    font-size: 4rem;
    border-bottom: 0.3rem solid rgba(90, 133, 64, 1);
}

.home-main .ongoing-git-form .form-class .form-main input,
.home-main .ongoing-git-form .form-class .form-main textarea {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
    font-size: 2rem;
}

.home-main .ongoing-git-form .form-class .form-main input::placeholder,
.home-main .ongoing-git-form .form-class .form-main textarea::placeholder {
    color: black;
}

.home-main .ongoing-git-form .form-class .form-main input {
    border: none;
    /* border-bottom: 1px solid rgba(0, 121, 70, 1); */
    border-bottom: 0.3rem solid rgba(90, 133, 64, 1);
}

.home-main .ongoing-git-form .form-class .form-main textarea {
    border-color: rgba(0, 121, 70, 1);
}

.home-main .ongoing-git-form .form-class .form-main button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 1rem;
    font-size: 2rem;
    width: 100%;
    margin-top: 2rem;
    background-color: rgba(0, 121, 70, 1);
    color: white;
}

/* .secondbanner {
    padding: 1rem;
} */

.second-banner-image img{
    height: 72rem !important;
}

/* .setimgleaves {
    width: 20rem;
} */

@media screen and (max-width:1190px) and (min-width:1079px) {
    .second-banner-image img{
        height: 58rem !important;
    }
}

@media screen and (max-width:1048px) {
    .second-banner-image img{
        height: 68rem !important;
    }
}


.hometext {
    font-weight: 600;
    font-size: 1.7rem;
    text-align: justify;
    height: 41rem;
    overflow: hidden;
}

@media screen and (max-width:992px) {
    .hometext {
        height: 100%;
        overflow: auto;
    }
}

.hometext-end {
    font-weight: 600;
    font-size: 1.7rem;
}


.btnhome {
    background: #5A8540;
    color: #fff;
    font-weight: 600;
    font-size: 1.8rem;
    border: none;
    text-align: end;

}

.carousel-image {
    height: 40rem !important;
}

.carasoualtitle {
    font-size: 4rem;
    color: #fff;
    font-weight: 600;
    margin-top: -20rem;
}

.carasoualtext {
    font-size: 2rem;
    color: #fff;
    font-weight: 500;
}

.carasoualtext-name {
    font-size: 2rem;
    color: #fff;
    margin-top: -1rem;
    font-weight: 500;
}

.carasoualarrow {
    font-size: 5rem;
    color: #fff;
    font-weight: 900;
}

.texthome{
    margin-bottom: 6rem;
}

.lastbannerhome {
    position: relative;

}

.lastbannertittle {
    font-size: 4rem;
    color: black;
    font-weight: 900;
}

.lasbannertext {
    position: absolute;
    /* height: 10rem; */
    top: 5rem;
    left: 7rem;
    font-size: 2rem;
    width: 70rem;
    padding: 2rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.4292) 68.23%, rgba(255, 255, 255, 0) 100%);

}

.lasbannertext p {
    text-align: justify !important;
}

@media screen and (max-width:680px) {
    .lasbannertext {
        top: 2rem;
        left: 2%;
        font-size: 2rem;
        width: 90%;
    }
}