
.completed-projects-carousel .project-carousel-main {
    width: 90%;
    margin-left: 5%;
}

.completed-projects-carousel .project-title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 4rem;
    margin-top: 2rem;
}

.completed-projects-carousel .project-carousel-main .carousel-image img{
    height: 65rem;
    width: 100%;
}

.completed-projects-carousel .project-carousel-main .slick-prev:before,
.completed-projects-carousel .project-carousel-main .slick-next:before {
    font-family: 'slick';
    font-size: 3rem;
    line-height: 0.1rem;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.completed-projects-carousel .project-carousel-main .slick-prev:before {
    position: absolute;
    margin-left: -2rem !important;
    margin-top: 0rem !important;
}

.completed-projects-carousel .project-carousel-main .slick-next:before {
    position: absolute;
    margin-left: -1rem !important;
    margin-top: 0rem !important;
}

@media screen and (max-width:480px) {
    .completed-projects-carousel .project-carousel-main .slick-prev:before {
        margin-left: -1rem !important;
    }
    
    .completed-projects-carousel .project-carousel-main .slick-next:before {
        margin-left: -2rem !important;
    }
}

/* .project-info .carousel-details-class{
    position: relative;
} */

.project-info{
    /* position: absolute; */
    margin-left: 60rem;
    /* width: 10%; */
    width: 40%;
    margin-top: -2rem;
    padding: 2rem;
    background-color: rgba(251, 251, 251, 0.62);
    /* background-color: rgba(173, 255, 47, 0.62); */
}

@media screen and (max-width:880px) {
    .project-info{
        padding: 1rem;
        margin-left: 24% !important;
        width: 55%;
        bottom: 4rem !important;
    }
}

@media screen and (max-width:480px) {
    .project-info{
        padding: 1rem;
        margin-left: 25% !important;
        width: 60%;
        bottom: 4rem !important;
    }
}


.project-info p {
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
}

.project-info .main-p {
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 1.7rem;
}

/* .project-info .available-plots {
    line-height: 0rem !important;
} */

.project-aminities{
    position: absolute;
    margin-left: 12%;
    top:5%;
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    padding: 2rem ;
    background-color: rgba(251, 251, 251, 0.62);
}

@media screen and (max-width:615px) {
    .project-aminities{
        margin-left: 10% !important;
    }
}

@media screen and (max-width:479px){
    .project-info{
        margin-left: 0.2%;
        bottom: 1rem;
        font-size: 1.8rem;
    }
    .project-aminities{
        font-size: 2rem;
        margin-left: 8.5% !important;
    }
}