* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}



#navbar-flex-id a {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.5rem !important;
}

#navbar-flex-id span {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.5rem !important;
}

.location-menu {
    padding: 1.5rem !important;
}

.location-menu li{
    /* border: 1px solid lightgrey; */
    margin-bottom: 0.5rem;
}


.location-menu .location-item {
    padding: 0.5rem !important;
    /* border: .1rem solid black; */
}

@media screen and (max-width:480px) {
    #navbar-flex-id a {
        font-size: 2.5rem !important;
    }
}

.navbar-flex {
    width: 100%;
}

@media screen and (max-width:480px) {
    .navbar-flex .hamburger-icon {
        font-size: 2.5rem;
    }
}