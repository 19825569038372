.home-summary-main {
    background-image: url('../Images/LeavesMainImage.png');
    padding: 7rem;
    background-size: cover;
}

@media screen and (max-width:480px) {
    .home-summary-main {
        background-image: url('../Images/LeafMobileViewImage.png');
    }
}

.home-summary-main .home-summary .summary-title {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 5rem;
    margin-top: 22rem;
}

.home-summary-main .home-summary .inner-circle {
    height: 28rem;
    width: 28rem;
    background-color: rgba(255, 255, 255, 0.32);
    border: 1rem white solid;
    border-radius: 50%;
    margin-top: 5rem;
    margin-bottom: 3rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.home-summary-main .home-summary .inner-circle h1 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2.5rem;
    margin-top: 11rem;
}