.completed-main {
    overflow: hidden;
}

.completed-main .section-1 {
    position: relative;
}

.completed-main .section-1 .ongoing-git-form img {
    height: 80rem;
    width: 100%;
}

.completed-main .section-1 .form-class {
    width: 40rem;
    padding: 0.2rem;
    background-color: rgba(0, 69, 108, 0.47);
    border: 0.5rem solid white;
    position: absolute;
    top: 20rem;
    left: 5%;
}

.completed-main .ongoing-git-form .form-class .form-main .git-heading {
    padding: 1rem 0rem;
    font-weight: bold;
    font-size: 2.5rem;
    border-bottom: 0.3rem solid white;
    color: white;
}

@media screen and (max-width:640px) {
    .completed-main .section-1 .form-class {
        top: 10rem;
    }
}

@media screen and (max-width:365px) {
    .completed-main .section-1 .ongoing-git-form img {
        height: 50rem;
    }
}

@media screen and (max-width:480px) {
    .completed-main .section-1 .ongoing-git-form img {
        height: 55rem;
    }
}

.completed-main .section-1 .form-class .form-main {
    /* background-color: rgba(0, 69, 108, 0.2); */
    padding: 1rem;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.completed-main .section-1 .form-class .form-main input,
.completed-main .section-1 .form-class .form-main textarea {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    background-color: transparent;
    width: 90%;
    padding: 0.5rem;
    margin-top: 1rem;
    font-size: 1.5rem;
    color: white;
}

.completed-main .section-1 .form-class .form-main input::placeholder,
.completed-main .section-1 .form-class .form-main textarea::placeholder {
    color: white;
}

.completed-main .section-1 .form-class .form-main input {
    border: none;
    border-bottom: 1px solid white;
}

.completed-main .section-1 .form-class .form-main textarea {
    border-color: white;
}

.completed-main .section-1 .form-class .form-main button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.7rem;
    padding: 1rem;
    width: 100%;
    margin-top: 2rem;
    background-color: rgba(0, 69, 97, 1);
    color: white;
}

.completed-projects-carousel {
    position: relative;
}

.completed-main .section-1 .project-highlights-main .project-image-description {
    padding: 2rem 5rem 8rem 5rem;
    margin-top: 1rem;
    background-color: rgba(0, 121, 70, 1);
}

.completed-main .section-1 .project-highlights-main .project-image-description .image-main {
    height: 80rem;
    padding: 3rem;
    /* margin-top: 8rem; */
    margin-top: 3rem;
    /* background-color: aqua; */
}

.completed-main .section-1 .project-highlights-main .project-image-description .image-main 
.main-amenity-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 5rem;
    color: white;
}

.completed-main .section-1 .project-highlights-main .project-image-description .image-main .outer-circle {
    /* height: 80%;
    width: 80%; */
    height: 60rem;
    width: 60rem;
    border-radius: 50%;
    background-color: #93B475;
    margin-left: 0rem;
    padding: 4rem;
    z-index: 1 !important;
    /* transform: rotate(-178.1deg) !important; */
}

.completed-main .section-1 .project-highlights-main .project-image-description .image-main .outer-circle .inner-circle {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 4rem;
}


.completed-main .section-1 .project-highlights-main .project-image-description .image-main .outer-circle .inner-circle .second-inner-circle {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 69, 108, 0.2)
}

.completed-main .section-1 .project-highlights-main .project-image-description .image-main .icon-class {
    height: 9rem !important;
    width: 70%;
    border-radius: 4.5rem;
    background-color: rgba(244, 244, 244, 1);
    padding: 3rem;
    margin-left: 15%;
}

/* @media screen and (max-width:480px) {
    .completed-main .section-1 .project-highlights-main .project-image-description .image-main .outer-circle {
        margin-left: -1rem;
    }

    .completed-main .section-1 .project-highlights-main .project-image-description .image-main .icon-class {
        margin-left: 7%;
        width: 90%;
    }
} */

.completed-main .section-1 .project-highlights-main .project-image-description .image-main .icon-class .icon-logo {
    margin-top: -2.1rem !important;
    background-color: white;
    height: 7rem;
    width: 7rem;
    border: 1px solid black;
    border-radius: 50%;
    padding: 1rem;
}

.completed-main .section-1 .project-highlights-main .project-image-description .image-main .icon-class .icon-logo img {
    height: 100%;
}

.completed-main .section-1 .project-highlights-main .project-image-description .description-main {
    color: white;
    margin-top: 17%;
}

.completed-main .section-1 .project-highlights-main .project-image-description .description-main-long {
    color: white;
    margin-top: 10%;
}

@media screen and (max-width:991px) {
    .completed-main .section-1 .project-highlights-main .project-image-description .description-main,
    .completed-main .section-1 .project-highlights-main .project-image-description .description-main-long {
        margin-top: 5%;
    }
}

@media screen and (max-width:767px) {
    .completed-main .section-1 .project-highlights-main .project-image-description .description-main,
    .completed-main .section-1 .project-highlights-main .project-image-description .description-main-long {
        margin-top: 5%;
    }
}

.completed-main .section-1 .project-highlights-main .project-image-description .description-main h1,
.completed-main .section-1 .project-highlights-main .project-image-description .description-main-long h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 4rem;
}

.completed-main .section-1 .project-highlights-main .project-image-description .description-main p,
.completed-main .section-1 .project-highlights-main .project-image-description .description-main-long p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-align: justify;
    font-size: 1.6rem;
    line-height: 2rem;
}

@media screen and (max-width:767px) {
    .completed-main .section-1 .project-highlights-main .project-image-description .description-main p,
    .completed-main .section-1 .project-highlights-main .project-image-description .description-main-long p {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}

@media screen and (max-width:480px) {
    .completed-main .section-1 .project-highlights-main .project-image-description .description-main p,
    .completed-main .section-1 .project-highlights-main .project-image-description .description-main-long p {
        font-size: 2.5rem;
        line-height: 3rem;
    }
}

.completed-main .section-1 .project-highlights-main .aminity-heading {
    margin-top: 5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 4rem;
}

.completed-main .section-1 .project-highlights-main .video-main {
    height: 60rem;
}

.completed-main .section-1 .project-highlights-main .layout-carousel-main .layout-heading {
    margin-top: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 4rem;
}