.property-main .container {
  position: relative;
  text-align: center;
  color: white;
}

.property-main .propertyimg {
  height: 60rem;
}

/* Bottom left text */
.property-main .bottom-left {
  position: absolute;
  bottom: 10%;
  left: 1.6rem;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  color: white;
  text-align: left;
  font-size: 1.7rem;
}

.property-main .lists {
  list-style: none !important;
}

.property-main .lists .property-title{
  color: white !important;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 2.5rem !important;
  letter-spacing: 0.1rem;
}

.property-main .titleproperty {
  font-size: 4rem;
}

/* Styles for small screens */
@media (max-width: 576px) {
  .property-main .bottom-left {
    font-size: 10px;
  }

  .property-main .titleproperty {
    font-size: 3rem;
  }
}

/* Styles for medium screens */
@media (min-width: 577px) and (max-width: 992px) {
  .property-main .bottom-left {
    font-size: 15px;
  }

  .property-main .titleproperty {
    font-size: 3rem;
  }
}