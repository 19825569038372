.main-contact .contact-main {
    height: 61.8rem;
}

.main-contact .contact-main .contact-content .contact-row-main .contact-details-main {
    padding: 1.3rem 2.5rem 35rem 0 !important;
}
@media screen and (min-width:1300px){
    .super .main-contact .contact-main {
        height: 66.6rem;
    }
    
    .super .main-contact .contact-main .contact-content .contact-row-main .contact-details-main {
        padding: 2.3rem 3.5rem 34rem 0 !important;
    }
    .super .details-main{
        height: 55rem !important;
    }
    
}
@media screen and (min-width:1200px) and (max-width:1300px){
   .super .main-contact .contact-main {
        height: 74.6rem;
    }
    
    .super .main-contact .contact-main .contact-content .contact-row-main .contact-details-main {
        padding: 6.3rem 3.5rem 40rem 0 !important;
    }
    .super .details-main{
        height: 60rem !important;
    }
    
}
@media screen and (max-width:991px) {
    .main-contact .contact-main .contact-content .contact-row-main .contact-details-main {
        padding-left: 2.5rem !important;
    }
}

@media screen and (max-width:997px) {
    .main-contact .contact-main {
        height: 71rem;
    }
}


@media screen and (max-width:990px) {
    .main-contact .contact-main {
        height: 192rem;
    }
}

@media screen and (max-width:974px) {
    .main-contact .contact-main {
        height: 190rem;
    }
}

@media screen and (max-width:880px) {
    .main-contact .contact-main {
        height: 183rem;
    }
}

@media screen and (max-width:871px) {
    .main-contact .contact-main {
        height: 178rem;
    }
}

@media screen and (max-width:790px) {
    .main-contact .contact-main {
        height: 177rem;
    }
}

@media screen and (max-width:769px) {
    .main-contact .contact-main {
        height: 185rem;
    }
    .super .social-icons {
        margin-top: 1rem;
    }
}

@media screen and (max-width:690px) {
    .main-contact .contact-main {
        height: 182rem;
    }
}

@media screen and (max-width:480px) {
    .main-contact .contact-main {
        height: 168rem;
    }
}

@media screen and (max-width:479px) {
    .main-contact .contact-main {
        height: 188rem;
    }
}

@media screen and (max-width:321px) {
    .main-contact .contact-main {
        height: 180rem;
    }
}