
.completed-main .layout-carousel-main {
    padding: 2rem 5rem 1rem 5rem;
    background-color: #E0F0D0;
}

.completed-main .layout-carousel-main .project-layoutCarousel-main {
    width: 100%;
    /* height: 50rem; */
}

.completed-main .layout-carousel-main .project-layoutCarousel-main .carousel-image img{
    height: 100%;
    width: 100%;
}